import { handleMic, handleMuteForAll } from "./helper-functions/handleMic.js";
import { handleLeave } from "./helper-functions/handleLeave.js";
import { handleLeavePlayer } from "./helper-functions/handleLeavePlayer.js";
import {
  handleVideo,
  handleMuteVideoForAll,
} from "./helper-functions/handleVideo.js";
import { handleSendLike } from "./helper-functions/handleSendLike.js";
import { handleSendDislike } from "./helper-functions/handleSendDislike.js";
import { handleSendChallenge } from "./helper-functions/handleSendChallenge.js";
import { handleTransport } from "./helper-functions/handleTransport.js";
import { handleConsumer } from "./helper-functions/handleConsumer.js";
import { handleStartGame } from "./helper-functions/handleGameStart.js";
import { handleDayNight } from "./helper-functions/handleDay&Night.js";
import { sendMessage } from "./helper-functions/handleChat.js";
import {
  kickOutUser,
  handleAdminMute,
  handleAdminMuteVideo,
  handleAwakeSleep,
} from "./helper-functions/handleAdminActions.js";
import { handleMute } from "./helper-functions/handleUserActions.js";
import { handleMuteVideo } from "./helper-functions/handleUserActions.js";
import { updatePermission } from "./helper-functions/handleUserPermissions.js";
import { handleCapabilities } from "./helper-functions/handleCapabilities.js";
import { startStream } from "./helper-functions/handleStartStream.js";
import { startConsumeProducer } from "./helper-functions/handleStartConsumeProducer.js";
import { consume } from "./helper-functions/handleConsume.js";
import { handleUserAction } from "./helper-functions/handleLikeDislikeChallangeState.js";
import { handleRoomCounter } from "./helper-functions/handleRoomCounter.js";
import handleRejoin from "./helper-functions/handleRejoin.js";
const userCounter = (userIndex) => {
  let userCount;
  switch (userIndex) {
    case 1:
      userCount = "one";
      break;
    case 2:
      userCount = "two";
      break;
    case 3:
      userCount = "three";
      break;
    case 4:
      userCount = "four";
      break;
    case 5:
      userCount = "five";
      break;
    case 6:
      userCount = "six";
      break;
    case 7:
      userCount = "seven";
      break;
    case 8:
      userCount = "eight";
      break;
    case 9:
      userCount = "nine";
      break;
    case 10:
      userCount = "ten";
      break;
    case 11:
      userCount = "eleven";
      break;
    case 12:
      userCount = "twelve";
      break;
    case 13:
      userCount = "thirteen";
      break;
    case 14:
      userCount = "fourteen";
      break;
    default:
      break;
  }
  return userCount;
};

export {
  userCounter,
  handleMic,
  handleMuteForAll,
  handleLeave,
  handleLeavePlayer,
  handleVideo,
  handleMuteVideoForAll,
  handleSendLike,
  handleSendDislike,
  handleSendChallenge,
  handleTransport,
  handleConsumer,
  handleStartGame,
  handleDayNight,
  sendMessage,
  kickOutUser,
  handleAdminMute,
  handleMute,
  handleAdminMuteVideo,
  handleMuteVideo,
  updatePermission,
  handleAwakeSleep,
  handleCapabilities,
  startStream,
  startConsumeProducer,
  consume,
  handleUserAction,
  handleRoomCounter,
  handleRejoin,
};
