import { params } from "../../../hooks/game-room/handlegameRoom.js";
import CaluclateStartTime from "../../../components/game-lobby/CaluclateStartTime.js";
import { alertDialog } from "../../../features/global/globalSlice.js";
import { handleLeave } from "./handleLeave.js";
export const handleRoomCounter = () => {
  let {
    isGameStart,
    roomData,
    dispatch,
    timerAlertShownRef,
    leaveClickedRef,
    t,
  } = params;

  const roomStartedTime = CaluclateStartTime({
    startTime: roomData?.startTime,
    format: "minutes",
  });
  if (!isGameStart && roomData?.state !== "STARTED") {
    // Calculate the time since the room started in minutes
    if (
      roomStartedTime >= 5 &&
      roomStartedTime < 10 &&
      !timerAlertShownRef.current
    ) {
      timerAlertShownRef.current = true;
      dispatch(
        alertDialog({
          title: t("APP.ALERT_DIALOGS.TITLE.GAME_ROOM.LESS_TIME_LEFT"),
          icon: "warning",
        }),
      );
    }

    if (roomStartedTime > 10 && !leaveClickedRef.current) {
      leaveClickedRef.current = true;
      handleLeave();
      dispatch(
        alertDialog({
          title: t("APP.ALERT_DIALOGS.TITLE.GAME_ROOM.NO_ACTIVITY_LEAVE_ROOM"),
          icon: "error",
        }),
      );
    }
  }
};
