import { params } from "../../../hooks/game-room/handlegameRoom.js";

let pingTimeout;
let noInternetCount = 0; // Counter for tracking internet unavailability

export const disconnectSocket = () => {
  const { socket, setInternetStatus, roomId } = params;

  // Periodically send a "ping" message to the server
  const pingInterval = setInterval(() => {
    pingTimeout = setTimeout(() => {
      setInternetStatus(false);
      console.error("no internet...");
      noInternetCount++;

      if (noInternetCount >= 4) {
        console.error("Connection is unstable. Multiple pings missed.");
        // clearInterval(pingInterval);
        // clearTimeout(pingTimeout);
      }
    }, 2000);
    socket.emit("ping", roomId, async (response) => {
      setInternetStatus(true);
      clearTimeout(pingTimeout);
      noInternetCount = 0;
    });
  }, 5000); // Send ping every 5 seconds

  socket.on("disconnect", async () => {
    console.log("socket disconnected");
    window.location.reload();
  });
  return { pingInterval, setTimeout };
};
