import authReducer from "./auth/authSlice.js";
import globalReducer from "./global/globalSlice.js";
import gameLobbyReducer from "./gameLobby/gameLobbySlice.js";
import socketReducer from "./socket/socketSlice.js";
import gameRoomReducer from "./gameRoom/gameRoomSlice.js";

export {
  authReducer,
  globalReducer,
  gameLobbyReducer,
  socketReducer,
  gameRoomReducer,
};
